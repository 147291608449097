@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a:not(.unstyled) {
    @apply underline decoration-ssw-gray-light decoration-1 transition duration-300 hover:border-sswRed hover:text-sswRed;
  }
  html,
  body {
    @apply scroll-smooth;
    /* see https://github.com/SSWConsulting/SSW.Website/issues/1147 */
    font-size: 18px;
  }
  h2.callToAction {
    @apply mx-auto mb-4 mt-4 w-fit pb-5 pt-15 text-4xl;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-sans leading-tight;
  }

  h1 {
    @apply my-4 pb-5 pt-15 text-4xl;
  }

  h2 {
    @apply mb-2.5 mt-10 text-3xl;
  }

  h3 {
    @apply mb-0.5 mt-2.5 text-2xl;
  }

  h1 + h2 {
    @apply !mt-5;
  }

  small {
    @apply text-xs;
  }

  figure {
    @apply mb-4;
  }
}

@layer utilities {
  .mask-horizontal-fade {
    -webkit-mask-image: linear-gradient(
      to right,
      transparent,
      black 10%,
      black 90%,
      transparent
    );
    mask-image: linear-gradient(
      to right,
      transparent,
      black 10%,
      black 90%,
      transparent
    );
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@layer components {
  .icon::before {
    @apply inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  a.done,
  button.done {
    @apply items-center justify-center rounded border-none bg-sswRed px-4 py-3 align-middle text-sm font-normal uppercase leading-[18px] text-white no-underline !outline-sswRed hover:!bg-sswDarkRed hover:!text-white;
  }

  a:hover:not(.unstyled) {
    @apply decoration-sswRed;
  }

  .m-icon {
    @apply mr-1;
  }

  .customTable table,
  .customTable th,
  .customTable td {
    border: 1px solid;
    padding: 8px;
  }

  .customTable li {
    list-style-type: square;
  }

  @media print {
    #chatbase-message-bubbles,
    #chatbase-bubble-button {
      display: none !important;
    }
    .no-print,
    .no-print * {
      display: none !important;
    }
    .pagebreak {
      page-break-before: always;
    }
  }
}
